// @ts-nocheck
/* eslint-disable */
module.exports = {
  languageData: {
  "plurals": function (n, ord) {
    var s = String(n).split("."),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2);
    if (ord) return n10 == 1 && n100 != 11 ? "one" : n10 == 2 && n100 != 12 ? "two" : n10 == 3 && n100 != 13 ? "few" : "other";
    return n == 1 && v0 ? "one" : "other";
  }
},
  messages: {
    "<0>Hello {0},</0><1>You've sent your Notice To Repair letter. Attached to this email is a PDF copy for your records.</1>": function (a) {
  return ["<0>Hello ", a("0"), ",</0><1>You've sent your Notice To Repair letter. Attached to this email is a PDF copy for your records.</1>"];
},
    "<0>JustFix</0> builds tools for tenants, housing organizers, and legal advocates.": "<0>JustFix</0> builds tools for tenants, housing organizers, and legal advocates.",
    "<0>Repairs required</0>": "<0>Repairs required</0>",
    "<0>SAJE</0> empowers tenants in Los Angeles to fight for their homes and communities.": "<0>SAJE</0> empowers tenants in Los Angeles to fight for their homes and communities.",
    "Account settings": "Account settings",
    "Allow 14 days for a response": "Allow 14 days for a response",
    "Answer some basic questions about your housing situation, and we’ll automatically create a letter for you.": "Answer some basic questions about your housing situation, and we\u2019ll automatically create a letter for you.",
    "Anti-Harassment": "Anti-Harassment",
    "Are you sure you want to mail the letter yourself?": "Are you sure you want to mail the letter yourself?",
    "Attend SAJE’s <0>Tenant Action Clinic</0> if you're faced with a housing problem.<1/><2/>Get involved with SAJE to build power with your neighbors": "Attend SAJE\u2019s <0>Tenant Action Clinic</0> if you're faced with a housing problem.<1/><2/>Get involved with SAJE to build power with your neighbors",
    "Build your Letter": "Build your Letter",
    "Call LADBS at <0/> or visit <1>the LA County Department of Public Works</1> for more information": "Call LADBS at <0/> or visit <1>the LA County Department of Public Works</1> for more information",
    "Call LAHD at <0/>": "Call LAHD at <0/>",
    "Can my landlord retaliate against me for sending a letter?": "Can my landlord retaliate against me for sending a letter?",
    "Common questions": "Common questions",
    "Completed": "Completed",
    "Contact SAJE at <0/> or attend the <1>Tenant Action Clinic</1>": "Contact SAJE at <0/> or attend the <1>Tenant Action Clinic</1>",
    "Create an Account": "Create an Account",
    "Created by": "Created by",
    "Dates and times you’ll be available for repairs": "Dates and times you\u2019ll be available for repairs",
    "Dates the COVID-19 renter protections were violated": "Dates the COVID-19 renter protections were violated",
    "Dates the harassment occurred": "Dates the harassment occurred",
    "Dates when the landlord tried to access your home": "Dates when the landlord tried to access your home",
    "Details about the events": "Details about the events",
    "Document the harassment you and your family are experiencing and send a notice to your landlord.": "Document the harassment you and your family are experiencing and send a notice to your landlord.",
    "Download letter": "Download letter",
    "Email a copy to your landlord or property manager": "Email a copy to your landlord or property manager",
    "Email your letter to:": "Email your letter to:",
    "Estimated time to complete": "Estimated time to complete",
    "Finish creating your account": "Finish creating your account",
    "For LA City residents": "For LA City residents",
    "For LA county residents": "For LA county residents",
    "For LA residents": "For LA residents",
    "Frequently asked questions": "Frequently asked questions",
    "Get involved in your community": "Get involved in your community",
    "Go to JustFix homepage": "Go to JustFix homepage",
    "Go to SAJE homepage": "Go to SAJE homepage",
    "Go to form": "Go to form",
    "How do you want to send your letter?": "How do you want to send your letter?",
    "I agree to the JustFix <0>Terms of Use</0>.": "I agree to the JustFix <0>Terms of Use</0>.",
    "I am undocumented. Can I send a letter?": "I am undocumented. Can I send a letter?",
    "I don't have this information": "I don't have this information",
    "If the information above is not correct, go back to make changes.": "If the information above is not correct, go back to make changes.",
    "If you add your email address now, we'll email you a copy of your completed letter.": "If you add your email address now, we'll email you a copy of your completed letter.",
    "If your landlord or property manager doesn’t respond, you should file a complaint.": "If your landlord or property manager doesn\u2019t respond, you should file a complaint.",
    "If your landlord or property manager normally contact you by email, we recommend adding their email address above.": "If your landlord or property manager normally contact you by email, we recommend adding their email address above.",
    "In progress": "In progress",
    "I’m not comfortable creating a letter on my own. Who can help me?": "I\u2019m not comfortable creating a letter on my own. Who can help me?",
    "JustFix and SAJE are registered 501(c)(3) nonprofit organizations.": "JustFix and SAJE are registered 501(c)(3) nonprofit organizations.",
    "JustFix can text me to follow up about my housing issues.": "JustFix can text me to follow up about my housing issues.",
    "JustFix is preparing your letter": "JustFix is preparing your letter",
    "JustFix sent your letter on": "JustFix sent your letter on",
    "LA Tenant Action Center is for California residents only.": "LA Tenant Action Center is for California residents only.",
    "LaLetterBuilder is a collaboration between JustFix and legal organizations and housing rights non-profits in Los Angeles.": "LaLetterBuilder is a collaboration between JustFix and legal organizations and housing rights non-profits in Los Angeles.",
    "Landlord or property manager email": "Landlord or property manager email",
    "Landlord or property manager name": "Landlord or property manager name",
    "Landlord or property manager’s contact information": "Landlord or property manager\u2019s contact information",
    "Landlords must give 24-hour written notice to enter your unit. Make a formal request that your landlord respect your right to privacy.": "Landlords must give 24-hour written notice to enter your unit. Make a formal request that your landlord respect your right to privacy.",
    "Mail for free": "Mail for free",
    "Mail letter now for free": "Mail letter now for free",
    "Mail your letter to:": "Mail your letter to:",
    "Make sure all the information is correct.": "Make sure all the information is correct.",
    "Make sure you keep your schedule clear during the requested access dates:": "Make sure you keep your schedule clear during the requested access dates:",
    "Mark your calendar": "Mark your calendar",
    "Menu": "Menu",
    "My issue is urgent and time sensitive. What should I do?": "My issue is urgent and time sensitive. What should I do?",
    "My letters": "My letters",
    "Need Repairs in Your home? Take action today": "Need Repairs in Your home? Take action today",
    "Next steps": "Next steps",
    "Not sure yet? You can sign back in later to send your letter.": "Not sure yet? You can sign back in later to send your letter.",
    "Note to repair sent on behalf of {0}": function (a) {
  return ["Note to repair sent on behalf of ", a("0")];
},
    "Notice to Repair": "Notice to Repair",
    "Notice to repair letter": "Notice to repair letter",
    "Preview of your letter": "Preview of your letter",
    "Private Right of Action": "Private Right of Action",
    "Repairs needed in your home": "Repairs needed in your home",
    "Resources": "Resources",
    "Review your letter": "Review your letter",
    "Review your rights as a tenant": "Review your rights as a tenant",
    "Right to Privacy": "Right to Privacy",
    "Sample Notice to Repair letter": "Sample Notice to Repair letter",
    "Select a letter to get started": "Select a letter to get started",
    "Select a mailing method": "Select a mailing method",
    "Select the repairs you need and we’ll create your legally vetted letter.": "Select the repairs you need and we\u2019ll create your legally vetted letter.",
    "Select the repairs you need in your home": "Select the repairs you need in your home",
    "Start a new letter": "Start a new letter",
    "Start letter": "Start letter",
    "Start with the general area of repairs. You can follow up with your landlord or property manager in more detail once they receive your letter.": "Start with the general area of repairs. You can follow up with your landlord or property manager in more detail once they receive your letter.",
    "Street address": "Street address",
    "Support": "Support",
    "Tenant rights resources": "Tenant rights resources",
    "The City of LA allows residential tenants to sue for violations of COVID-19 renter protections. Document violations and notify your landlord.": "The City of LA allows residential tenants to sue for violations of COVID-19 renter protections. Document violations and notify your landlord.",
    "This is a free tool that notifies your landlord of repair issues via USPS Certified Mail®. This service is free and secure.": "This is a free tool that notifies your landlord of repair issues via USPS Certified Mail\xAE. This service is free and secure.",
    "USPS tracking number:": "USPS tracking number:",
    "Until then, here are some other forms you can fill, print and mail yourself.": "Until then, here are some other forms you can fill, print and mail yourself.",
    "Use our tool to create a letter and we can mail it for free.": "Use our tool to create a letter and we can mail it for free.",
    "View as PDF": "View as PDF",
    "View letter": "View letter",
    "We created the LA Tenant Action Center with lawyers and non-profit tenant rights organizations to ensure that your letter gives you the most protections.": "We created the LA Tenant Action Center with lawyers and non-profit tenant rights organizations to ensure that your letter gives you the most protections.",
    "We found this email address for your landlord:": "We found this email address for your landlord:",
    "We recommend that you to go back and select “Mail for me”. If you wish to send the letter yourself, continue to see instructions.": "We recommend that you to go back and select \u201CMail for me\u201D. If you wish to send the letter yourself, continue to see instructions.",
    "We will email your letter to:": "We will email your letter to:",
    "We'll send your letter for you via certified mail in 1-2 business days, at no cost to you.": "We'll send your letter for you via certified mail in 1-2 business days, at no cost to you.",
    "We're working on adding more letters.": "We're working on adding more letters.",
    "We’ll explain additional actions you can take if your issue isn’t resolved.": "We\u2019ll explain additional actions you can take if your issue isn\u2019t resolved.",
    "We’ll send your letter to your landlord or property manager for free via certified mail. Or you can opt to print and mail it yourself.": "We\u2019ll send your letter to your landlord or property manager for free via certified mail. Or you can opt to print and mail it yourself.",
    "What if a repair I need is not listed?": "What if a repair I need is not listed?",
    "What information will I need?": "What information will I need?",
    "What's next?": "What's next?",
    "When do I need to send a letter to my landlord?": "When do I need to send a letter to my landlord?",
    "Where can I add more details about the issues in my home?": "Where can I add more details about the issues in my home?",
    "Where do I find this information about my landlord or property manager?": "Where do I find this information about my landlord or property manager?",
    "Who is your landlord or property manager?": "Who is your landlord or property manager?",
    "You downloaded this letter on {dateString} to print and send yourself.": function (a) {
  return ["You downloaded this letter on ", a("dateString"), " to print and send yourself."];
},
    "You'll need to download the letter to print and mail yourself.": "You'll need to download the letter to print and mail yourself.",
    "Your Notice to Repair letter": "Your Notice to Repair letter",
    "Your Notice to Repair letter and important next steps": "Your Notice to Repair letter and important next steps",
    "Your tracking number will appear here once the letter has been sent.": "Your tracking number will appear here once the letter has been sent.",
    "free": "free",
    "laletterbuilder.emailToLandlordBody": function (a) {
  return ["<0>Please see letter attached from <1/>.</0><2>In order to document communications and avoid misunderstandings, please correspond with <3/> via email at <4>", a("0"), "</4> or mail rather than a phone call or in-person visit.</2>"];
},
    "laletterbuilder.emailToLandlordBodyNoEmail": "<0>Please see letter attached from <1/>.</0><2>In order to document communications and avoid misunderstandings, please correspond with <3/> via mail rather than a phone call or in-person visit.</2>",
    "laletterbuilder.explanationAboutWhyWeMadeThisSite": "We made this site because xyz",
    "laletterbuilder.faq.retaliation": "Exercising your tenant rights can be scary. Remember it is within your right to ask for repairs and live in a home free of harassment. If your landlord is retaliating against you, <0>contact SAJE</0> to speak with a housing rights organizer.",
    "laletterbuilder.faq.timesensitive": "If you live in the City of Los Angeles, call Urgent Repair Program at <0/>. If you live in a non-incorporated area of the County of Los Angeles, Call Consumer & Business Affairs at <1/>.",
    "laletterbuilder.faq.undocumented": "Yes, you can send a letter. Your immigration status does not affect your tenant rights.",
    "laletterbuilder.faq.whentosend": "Create a letter to formally request repairs or document harassment situations. The letter creates a paper trail of your communication if you decide to contact the Los Angeles Housing Department (LAHD) or the appropriate department.",
    "laletterbuilder.faq.whocanhelp": "Give SAJE a call at <0/> and let them know you need help creating a letter. You can also attend a <1>Tenant Action Clinic</1>.",
    "laletterbuilder.habitability.access-intro": "Below are dates that I am available to be at my home to let in a repair worker. Please contact me (using the information provided at the top of this letter) in order to make arrangements.",
    "laletterbuilder.habitability.access-title": "Available access dates",
    "laletterbuilder.habitability.access-warning": "Be advised that you are required by law to provide a <0>24-hour written notice of intent</0> to enter the unit to make repairs pursuant California Civil Code 1954. Anyone coming to perform a repair inspection and/or repairs should arrive on the date and time mutually agreed upon.",
    "laletterbuilder.habitability.consequences": "You have 10 business days from the date of this letter to address the repairs outlined. If you do not respond within a reasonable time, as you are required to do under Civil Code Sections 1941 and 1941.1, I will report it to the Los Angeles Housing and Community Investment Department (LAHD), the Los Angeles Department of Public Health/(LADPH) and the Department of Building and Safety (LADBS).",
    "laletterbuilder.habitability.intro": "NOTICE IS HEREBY GIVEN that the conditions listed below may be dangerous to my family and me. We did not cause the conditions. Please repair the conditions as soon as possible.",
    "laletterbuilder.issues.addRepairDetails": "You can share more details with your landlord or property management company through email, text messages, or through certified mail. If the repair issue is urgent contact <0>LAHD</0> at <1/>. If you do not live in the City of LA, visit <2>the LA County Department of Public Works</2> for more information.",
    "laletterbuilder.issues.repairNotListedv2": "The Notice of Repair letter will formally document your request for repairs. If you have additional repair needs beyond what is on this repair check list it is important to maintain a proper paper trail. Only contact your landlord or property management company through email, text messages, or through certified mail. If you do decide to call your landlord/ property manager we recommend emailing or texting them details of the conversation afterwards.",
    "laletterbuilder.landlord.whereToFindInfo": "By law your landlord is required to provide contact information. If you\u2019re unable to get this information, attend the <0>Tenant Action Clinic</0> to get help.",
    "laletterbuilder.madeByBlurb": "LaLetterBuilder is made by <0>JustFix</0>, a non-profit organization that co-designs and builds tools for tenants, housing organizers, and legal advocates fighting displacement in New York City.",
    "laletterbuilder.retaliationInfo": "<0>If your landlord is retaliating against you for exercising your rights, you can:</0><1><2><3>Attend SAJE'S <4>Tenant Action Clinic</4></3></2><5><6>File a complaint with <7>Los Angeles Housing Department (LAHD)</7>if you live in the City of LA or <8>Public Health</8> if you live someplace else in LA County</6></5></1>",
    "laletterbuilder.reviewTenantRightsIntro": "Tenants have a right to a safe home, without harassment. Sending a letter to notify your landlord is within your rights.",
    "latenants.justfix.org <0/>sent on behalf of <1/>": "latenants.justfix.org <0/>sent on behalf of <1/>",
    "mins": "mins",
    "no printing": "no printing",
  },
};